import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/RADIOACTIVITY TESTING _ GOVRMENT APPROVED PSIA/1.webp';
import img2 from '../images/RADIOACTIVITY TESTING _ GOVRMENT APPROVED PSIA/2.webp';
import img3 from '../images/RADIOACTIVITY TESTING _ GOVRMENT APPROVED PSIA/3.webp';
import img4 from '../images/RADIOACTIVITY TESTING _ GOVRMENT APPROVED PSIA/4.webp';
import Details from './Details';


const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'}
   
  ]

const detail_info =[
  {id:1,
  title:'RADIOACTIVITY TESTING & GOVRMENT APPROVED PSIA',
  detail:`We have the latest equipment designed to detect even minute traces of radioactivity, explosives or other dangerous material which may be present at loading- discharging site and in the containers that are being loaded.
Our inspectors are qualified, trained and motivated to carry out pre- shipment inspection quickly and effectively with maximum efficiency.`}
]
function Radioactivity() {
  return (
    <div className="Home">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Footer/>
    </div>
  );
}

export default Radioactivity;