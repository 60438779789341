import { Outlet } from 'react-router-dom'; // This allows nested routing

function Services() {
  return (
    <div>
     
      {/* This is where the child routes will render */}
      <Outlet />
    </div>
  );
}

export default Services;
