import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/PROJECT CARGO, HAVEY LIFT _ SPECIALISED CARGO SURVEYS/1.webp';
import img2 from '../images/PROJECT CARGO, HAVEY LIFT _ SPECIALISED CARGO SURVEYS/2.webp';
import img3 from '../images/PROJECT CARGO, HAVEY LIFT _ SPECIALISED CARGO SURVEYS/3.webp';
import img4 from '../images/PROJECT CARGO, HAVEY LIFT _ SPECIALISED CARGO SURVEYS/4.webp';
import Details from './Details';




const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'}
  ]

const detail_info =[
  {id:1,
  title:'PROJECT CARGO, HEAVY LIFT & SPECIALISED CARGO SURVEYS',
  detail:'Our team of surveyors can systematically assist with ensuring that the Project cargo, heavy lift and specialized cargo being discharged / loaded is done safely, efficiently with diligent attention to detail to ensure the cargo is stowed and secured sufficiently to prevent movement, damage to the cargo or the vessel during sea transit.'}
]
function ProjectCargo() {
  return (
    <div className="ProjectCargo">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info} />
      <Footer/>
    </div>
  );
}

export default ProjectCargo;