import logo from '../images/mil-logo-removebg-preview.png';
// import small_logo from '../images/mil-logo-removebg-small.png';

import { useState } from 'react'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import { 
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon} from '@heroicons/react/20/solid'
import { Link,Route,Routes,NavLink } from 'react-router-dom';
import Contact from './ContactUs';

const products = [
  { name: 'Minerals, Ores & Alloys.',  to: '/services/minerals'},
  { name: 'Pre-loading, Discharge & Outrun Surveys.',  to: '/services/pre-loading'},
  { name: 'P & I Club Condtion / Entry Surveys.',  to: '/services/pi_club'},
  { name: 'Draft, Bunker Survey & On-Hire / Off-Hire Condition.',  to: '/services/draft_bunker'},
  { name: 'Project Cargo, Heavy Lift & Specialized Cargo Surveys.',  to: '/services/project_cargo'},
  { name: 'Agricultural Products & Fertilizers.', to: '/services/agricultural_products'},
  { name: 'Liquid Bulk Cargo.', to: '/services/liquid_bulk_cargo'},
  { name: 'Container Surveys – Inspection, Stuffing & De-Stuffing, Cargo Securing Surveys.', to: '/services/container_surveys'},
  { name: 'Radioactivity Testing & Government Approved PSIA.', to: '/services/radioactivity'},
  { name: 'Incident / Injury Investigation Survey.', to: '/services/incident_injury'},


]
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]



function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header className=" bg-gray-900/60 w-full fixed text-white  p-0 z-10 ">
      <nav aria-label="Global" className="mx-auto flex  items-center justify-between p-3 lg:px-3 text-white">
        <div className="flex">
          <Link to='/'>
            <img
              alt=""
              src={logo}
              
              className="h-12 w-auto"
            />  
         </Link>
          </div>
        <div className="flex bg-cover bg-center">
          <span className=" text-sm md:text-xl  lg:text-2xl leading-relaxed justify-center bg-center text-center font-black"  >INTERNATIONAL AND <br></br> INDEPENDENT INSPECTION COMPANY</span>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="size-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm/6 font-semibold text-white">
              Services
              <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-white" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -right-full  top-full z-10 mt-8 p-1 w-screen max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group  relative flex items-center gap-x-2 rounded-lg p-0 text-sm/6 hover:bg-gray-100"
                  >
                    
                    <div className="flex-auto">
                      <Link to={item.to} className="block font-semibold  text-black no-underline">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              
            </PopoverPanel>
          </Popover>
          
          <Link to='/contact' className="text-sm/6 font-semibold text-white no-underline " target='_self'>
            Contact Us
          </Link>
        </PopoverGroup>
        
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
           
            <Link to='/'>
              <img
                alt="logo"
                src={logo}
                className="h-auto w-auto "
                
  
              />
          </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-red-500"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-2">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    Services
                    <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                       {[...products].map((item) => (
                      <Link
                        key={item.name}
                        
                        to={item.to}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50 no-underline"
                        
                        onClick={() => setMobileMenuOpen(false)} // Close menu after navigation
                      >
                        {item.name}
                      </Link>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Link to='/contact' className="text-sm/6 font-semibold text-gray-900 no-underline" target='_self'>
            Contact Us
          </Link>
               
               
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <Routes>
      {/* <Route path='/about' element={<About/>}> </Route> */}
      <Route path='/contact' element={<Contact/>}> </Route>

      {/* <Route path='/' element={<Home/>} exact={true}> </Route>
      <Route path='*' element={<PageNotFound/>}></Route> */}

</Routes>
    </header>
  );
}

export default Header;