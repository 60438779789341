import React from "react";
// import "../css/FullScreenImage.css";
import { Carousel,CarouselItem } from "react-bootstrap";




function FullScreenImage(data) {

 
  return ( 
    
     <Carousel interval={2000} indicators={false} controls={false} touch={true} slide={true} wrap={true} as={'div'} className="h-[100vh] md:h-[100vh] bg-cover bg-center   flex-auto items-end">
      {data.data.map((data,key)=>(
        <CarouselItem key={key} as={'div'} >
          <img src={data.src} alt={data.alt} id={data.id} className="lg:w-full md:w-auto  sm:w-auto flex h-[100vh] md:h-[100vh]"></img>
        </CarouselItem>
      ))}
     
    
     
      </Carousel>
     
      

      
  );
}

export default FullScreenImage;



