import React from "react";
import "../css/Footer.css";

function Footer() {
  return (
    <footer className="footer bg-black/90 text-slate-50  md:text-sm">
      &copy; 2024 Your Website | All Rights Reserved by DP  
    </footer>
  );
}

export default Footer;