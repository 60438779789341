import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/1.webp';
import img2 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/2.webp';
import img3 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/3.webp';
import img4 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/4.webp';
import img5 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/5.webp';
import img6 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/6.webp';
import img7 from '../images/P& I CLUB-CARGO CONDITION,DAMAGE SURVEYS/7.webp';
import Details from './Details';




const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'},
    {id:5,src:img5,alt:'Image 5'},
    {id:6,src:img6,alt:'Image 6'},
    {id:7,src:img7,alt:'Image 7'},
  ]

  const detail_info = [
    {
      id: 1,
      title: 'P & I CLUB CONDITION/ ENTRY SURVEYS',
      detail: `As surveyors, we are appointed on behalf of P&I Clubs where onboard vessel inspections are carried out. Investigations of cargo damage or loss claims on behalf of shippers, receivers, carriers, and P&I Clubs are performed to determine the possible cause, nature, and extent of any damage that may have occurred. Our highly professional and exceedingly experienced surveyors are always on duty to undertake various surveys for P&I Clubs and marine insurance companies.
  
  We can provide the required survey, including but not restricted to:
    • Vessel Loading Equipment Survey
    • Cargo Handling with Regard to Stowage, Trimming
    • Securing/Lashing of Cargo
    • Hold Condition, Cleanliness, and Water Tightness
    • Hull Damage Survey
    • Container Damage Survey
    • Cargo Damage Survey
    • Vessel Structure Survey
    • Tally assistance as required`
    }
  ];
function PIClub() {
  return (
    <div className="PIClub">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Footer/>
    </div>
  );
}

export default PIClub;