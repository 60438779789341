// Feedback.js

import React, { useState } from "react";
import axios from "axios";
// import "../css/ContactUs.css";


const Feedback = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",

    });
    const [responseMessage, setResponseMessage] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://infy-developers-test.in/SendEmail.php", formData,
                {
                    headers: {
                        "Content-Type": "application/json", // Send data as JSON
                    },
                }
            );
            setResponseMessage(response.data.message);
            setFormData({ name: "", email: "", mobile: "",message: "" });
        } catch (error) {
            console.error("Error sending form data.",error);
            setResponseMessage("Error sending message. Please try again later.");
        }
    };

    return (
  
        // {/* <div className="contact-container"> */}
        <>
            <form onSubmit={handleSubmit}>
                <p className="text-2xl text-center font-bold">Contact Us</p>
                <label className="text-sm" htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 
                                        text-gray-600 text-sm rounded-lg 
                                        focus:border-blue-500 w-full p-2.5"
                />
                <label className="text-sm" htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 
                                        text-gray-600 text-sm rounded-lg 
                                        focus:border-blue-500 w-full p-2.5"
                />
                <label className="text-sm" htmlFor="email">Mobile</label>
                <input
                    type="mobile"
                    id="mobile"
                    name="mobile"
                    placeholder="Your Mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 
                                        text-gray-600 text-sm rounded-lg 
                                        focus:border-blue-500 w-full p-2.5"
                />
                <label className="text-sm" htmlFor="message">Message</label>
                <textarea
                    id="message"
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 
                                        text-gray-600 text-sm rounded-lg 
                                        focus:border-blue-500 w-full p-2.5"
                />
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 
                                        text-white font-bold 
                                        py-2 px-4 rounded w-full">Send</button>
                {responseMessage && <p>{responseMessage}</p>}
            </form>
        </>
      
      
    );
};

export default Feedback;
