import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/LIQUID BULK CARGO/1.webp';
import img2 from '../images/LIQUID BULK CARGO/2.webp';
import Details from './Details';



const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
  ]
const detail_info =[
  {id:1,
  title:'LIQUID BULK CARGO',
  detail:`Our specialized services prior loading-discharge include Inspection, sampling of shore tanks, Witnessing, analysis of cargoes, Inspection, Ullaging of vessel's tanks, Inspection of vessel's shore / lines, Sampling of cargoes and continuous attendance during operations.

Types of Commodities include Crude oil, Petroleum Products, Petrochemicals, Vegetable Oils, Chemicals, Etc.`}
]
function LiquidBulkCargo() {
  return (
    <div className="LiquidBulkCargo">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Footer/>
    </div>
  );
}

export default LiquidBulkCargo;