import React from "react";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const ContactMap = () => {
    const position = [19.079561, 72.896377]; // Example coordinates

    return (
        <>
            <MapContainer center={position} zoom={15} className="h-full w-full">
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    
                />
                <Marker position={position}>
                    <Popup>Your location</Popup>
                </Marker>
            </MapContainer>
        </>
    );
};

export default ContactMap;
