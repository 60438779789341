import React from "react";

import "../css/Details.css";
import { CardImgOverlay } from "react-bootstrap";

function Details(props) {
  return (
  
    <CardImgOverlay as={'div'} className=" p-4 text-center" class='details'>
    <   div className="text-white bg-gray-900/60 w-full p-2 text-center" >
        {props.data.map((data,key)=>(
        <>
        <h1 key={key} className=" text-base lg:text-2xl font-black " >{data.title} </h1>
        <p key={key} className=" text-xs lg:text-xl  leading-4  ">{data.detail}</p>
        </>

        ))}
        
      </div>
      </CardImgOverlay>
  );
}

export default Details;