// Card.js

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone,faEnvelope,faLocationPin} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feedback from "./Feedback";
import ContactMap from "./ContactMap";
import { Card } from "react-bootstrap";
import "../css/CardComponent.css";

library.add(faPhone,faEnvelope,faLocationPin);

export default function CardComponent() {
  return (
    <div class="grid  h-full place-content-center im grid-cols-12 gap-x-28 justify-evenly main_container p-10" >
      <div
        className="contain-content h-full col-span-12   md:col-span-7 lg:col-span-9
                            rounded-xl bg-white bg-clip-border 
                            text-gray-700 shadow-2xl"
      >
      
        
            <div className="h-full flex items-center p-6  ">
            <ContactMap />
            <Card className="flex">
              <h5
                className="text-center mr-4 mb-2 
                                   block font-sans text-xl 
                                   font-semibold text-blue-gray-900 
                                   antialiased"
              >
                Reach Us At
              </h5>
              <ul>
                <li className="mt-2">
                  <span>
                    <FontAwesomeIcon icon="phone" />{" "}{" "}
                  </span>
                  +91-8105445279
                </li>
                <li className="mt-2">
                  <span>
                  <FontAwesomeIcon icon="envelope" />{" "}{" "}
                  </span>
                  <span>operations@mil-india.net</span>
                </li>
                <li className="mt-2">
                  <span>
                  <FontAwesomeIcon icon="location-pin" />{" "}{" "}
                  </span>
                  C-606, NEELKANTH BUSINESS PARK,
                  <span className="pl-4">VIDYAVIHAR, WEST, MUMBAI -400086</span>
                </li>
              </ul>
              </Card>
             
            </div>
          
          
      
      </div>


      <div
        class=" col-span-12 md:col-span-5 lg:col-span-3 rounded-xl 
                         bg-white text-gray-700 shadow-2xl p-6"
      >
        <Feedback />
      </div>
    </div>
  );
}
